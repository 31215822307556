import locationService from '../../../services/supplier/location'

export default {
    data() {
        return {
            total: 0,
            items: [],
            searchQuery: {},
            header: [
                {
                    label: 'File Name',
                    name: 'file_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Total Rows',
                    name: 'total_rows',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Uploaded By',
                    name: 'created_datetime',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center'
                }
            ],
        }
    },
    methods: {
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {
            locationService.findLocationFile({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
                ...this.search
            }).then(resp=>{
                if(!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                }
            })
        },
        download (item) {
            locationService.getLocationFile(item.id).then(resp=>{
                if(!resp.error) {
                    this.openExternalLink(resp.data.d.data)
                }
            })
        }
    }
}